@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PTSerif_BOLD";
  src: url("/public/fonts/PT_Serif/PTSerif-Bold.ttf");
}

@font-face {
  font-family: "Rubik";
  src: url("/public/fonts/Rubik/Rubik-VariableFont_wght.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primary {
  color: #2a1451;
}
.secondary {
  color: #6a5b85;
}

.linkColor {
  color: #4951ff;
}
.neutralColor {
  color: #b9c3f3;
}
.muteColor {
  color: #b9c3f3;
}
.bodyContainer {
  margin-top: 30%;
}
.muteBackground {
  background-color: #dee3f2;
  border-color: #dee3f2;
}
.consents_option {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(42, 20, 81, 0.1);
  border-radius: 10px;
}

.btn_primary {
  background-color: #4951ff;
  border-color: #4951ff;
}

.btn_outline_primary {
  border-color: #4951ff;
  color: #4951ff;
}

.btn_primary:hover {
  background-color: #2e1982;
  border-color: #2e1982;
}

.icon_primary {
  color: #323232;
}

.btn_disable {
  border: 2px solid #dee3f2;
}

.min-w-30 {
  min-width: 30%;
}

.line28_44 {
  line-height: 28.44px;
}

.line79_5 {
  line-height: 79.5px;
}

.line18_96 {
  line-height: 18.96px;
}

.line16_59 {
  line-height: 16.59px;
}

.line16 {
  line-height: 16px;
}
